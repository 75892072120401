<template>
  <div id="view-about">
    <article class="">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/sinfo_48x48.png" class="icon" alt="Support Icon" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title is-5">About</div>
            <p>
              Initially operating under the umbrella of a partnership with
              Satori &amp; Associates, Inc<a
                href="#fn:1"
                id="fnref:1"
                title="see footnote"
                class="footnote"
                >[1]</a
              >, Druware has been establishing itself as it&#8217;s own
              corporation. Druware itself, was started by Andy Satori in 1995 as
              a place to publish the results of several &#8216;side&#8217;
              projects. One of those became a part of Object Desktop for Windows
              as sold by Stardock, Inc<a
                href="#fn:2"
                id="fnref:2"
                title="see footnote"
                class="footnote"
                >[2]</a
              >.
            </p>

            <p>
              Later, as the owner and lead developer made the personal
              transition to the Mac platform, the company began to move that way
              as well. Today, Druware, though capable of Windows development,
              focuses almost exclusively on the Mac market. Specifically
              specializing in the Mac database marketplace, with ODBC and
              PostgreSQL based projects, as well as distribution and support of
              the PostgreSQL for Mac database engine.
            </p>

            <p>
              With this foundation in place, Druware is now moving into the
              tools segment of the database marketplace, and in addition to
              building a suite of tools focused on managing the data in the
              small business, after all, who knows better what a small business
              requires, than a small business itself.
            </p>

            <p>
              Much of the current product line is either built in part or is
              fully available under the auspices of an open source license. We
              are building commercial products as well, but feel that the proper
              balance of community and commercial must be found for our long
              term success.
            </p>

            <p>
              As with many things in life, as we age, we sometimes go off in
              wildly different directions. So it is that there is another
              project that is growing, but built entirely on the foundations of
              what Druware has built. In many ways, that project now drives the
              continued work on what is here. That project is
              <a href="http://www.weareogre.com" target="new">O.G.R.E.</a> and
              revolves around building a community and platform of age group
              athletes competing in endurance sports
            </p>
          </div>
          <div class="footnotes">
            <ol>
              <li id="fn:1">
                <p>
                  Satori &amp; Associates, Inc and Druware remain closely
                  related.
                  <a
                    href="#fnref:1"
                    title="return to article"
                    class="reversefootnote"
                    >&#160;&#8617;</a
                  >
                </p>
              </li>
              <li id="fn:2">
                <p>
                  <a href="http://www.stardock.com" target="new">Stardock</a> is
                  now two companies, a utilities company and game company.
                  <a
                    href="#fnref:2"
                    title="return to article"
                    class="reversefootnote"
                    >&#160;&#8617;</a
                  >
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "view.about",
  components: {},
  data: function() {
    return {
      session: {},
      models: {},
      list: []
    };
  },
  methods: {
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    }
  },
  mounted() {
    this.session = this.$attrs.session;
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1px) {
  #view-about {
    article {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
      .footnote {
        font-size: 8pt;
        vertical-align: top;
      }

      .footnotes {
        font-size: 8pt;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  #view-about {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
